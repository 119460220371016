<template>
  <footer class="footer footercontent" style="margin-top: 20px">
    <div class="container-home">
      <nav>
        <ul>
          <li><a href="https://globechek.com/" target="_blank">Company</a></li>

          <li>
            <a href="https://www.facebook.com/GlobeChek/" target="_blank"
              >Social</a
            >
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://www.globechek.com" target="_blank" rel="noopener">
          <span style="">Globe</span>
          <span style="font-weight: bold">Chek</span>
        </a>
        <!-- &nbsp; -->
        <span style="padding-left: 5px; font-size: 12px">
          Inc. What we see</span
        >
        <span style="padding-left: 5px; font-size: 12px">
          <i class="material-icons">remove_red_eye</i> may save your
          sight!</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
<style lang="scss" scoped>
.footercontent {
  padding: 0 0;
}
</style>
