<template>
  <div class="full-page">
    <md-toolbar md-elevation="0" class="md-toolbar-absolute">
      <div class="md-toolbar-row md-offset">
        <div><img src="@/assets/img/GlobeChek.png" /></div>
        <div class="md-toolbar-section-end">
          <md-list>
            <md-list-item href="#" @click="search()">
              <i class="fas fa-user-md" />
              <p class="hidden-sm">&nbsp;Search</p>
            </md-list-item>
            <md-list-item href="#" @click.prevent="register">
              <md-icon>person_add</md-icon>
              <p class="hidden-sm">&nbsp;Register</p>
            </md-list-item>
            <md-list-item
              href="#"
              v-if="!app.isLoggedIn"
              @click.prevent="login"
            >
              <md-icon>fingerprint</md-icon>
              <p class="hidden-sm">&nbsp;login</p>
            </md-list-item>
            <md-list-item href="#" v-else @click.prevent="logout">
              <md-icon>logout</md-icon>
              <p class="hidden-sm">&nbsp;Logout</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </md-toolbar>
    <div class="wrapper wrapper-full-page">
      <div
        :style="setBgImage"
        :class="setPageClass"
        class="page-header header-filter"
        filter-color="black"
      >
        <!-- <md-menu
          v-if="isPhone"
          class="phone-menu"
          md-size="small"
          md-direction="bottom-start"
          style="background-color: transparent"
        >
          <i class="fa fa-bars fa-2x" md-menu-trigger aria-hidden="true" />
          <md-menu-content>
            <md-menu-item @click="search()">
              Search
              <i class="fas fa-user-md shift-icon-left" />
            </md-menu-item>
            <md-menu-item @click="register()">
              Register
              <md-icon class="shift-icon-left">person_add</md-icon>
            </md-menu-item>
            <md-menu-item v-if="!app.isLoggedIn" href="#" class="shift-left">
              Login
              <md-icon class="shift-icon-right">fingerprint</md-icon>
            </md-menu-item>
            <md-menu-item
              v-if="app.isLoggedIn"
              class="shift-icon-right"
              href="#"
              @click.prevent="logout"
            >
              Logout
              <md-icon>logout</md-icon>
            </md-menu-item>
          </md-menu-content>
        </md-menu> -->
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
            style="min-width: 100%"
          >
            <router-view />
          </zoom-center-transition>
        </div>
        <authfooter />
      </div>
    </div>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";
import authfooter from "./Footer.vue";
import img from "@/assets/img/btn.png";
export default {
  name: "Home",
  components: {
    ZoomCenterTransition,
    authfooter,
  },
  inject: ["app"],
  data() {
    return {
      pageTransitionDuration: 300,
      responsive: false,
    };
  },
  computed: {
    setBgImage() {
      return {
        backgroundImage: `url(${img})`,
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    },
    isPhone() {
      if (window.screen.width < 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    login() {
      this.$router.push("/", () => {});
      this.$router.push("/login");
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        sessionStorage.clear();
        this.$router.push({ path: "/" });
      });
    },
    register() {
      this.$router.push("/register");
    },
    search() {
      this.$router.push("/doctor-search");
    },
  },
};
</script>
<style lang="scss" scoped>
.md-list-item-content {
  .md-icon {
    margin-right: 0px;
    margin-left: 13px;
  }
}
</style>
